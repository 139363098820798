<template>
    <div class="main">
        <Topbar/>
        <router-view :key="$route.fullPath"></router-view>
        <Footer/>
        
    </div>
</template>

<script>
import Topbar from './components/Topbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: "App",
  components: {
      Topbar,
      Footer
  },
  mounted() {
      document.title = "NewCreative";
  }
}
</script>

<style>

</style>