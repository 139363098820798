/*tslint:disable*/
import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from "./store"

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify'
import ClickOutside from 'vue-click-outside'

//import GAuth from 'vue-google-oauth2'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(ClickOutside);
Vue.use(VueRouter);

//const gauthOption = {
//  clientId: 'CLIENT_ID.apps.googleusercontent.com',
//  scope: 'profile email',
//  prompt: 'select_account'
//}
//Vue.use(GAuth, gauthOption)

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
