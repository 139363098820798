<template>
        <b-container class="footer">
            <b-row class="justify-content-md-center">
                <b-col class="footercol">
                    Deze website en inhoud, waaronder gepresenteerd werk zijn eigendom van NewCreative. <br> Gebruik voor commerciële doeleinden alleen toegestaan met toestemming. <br>  Niet-commercieel gebruik is onder voorbehoud toegestaan, mits u mij informeert waar de content voor gebruikt wordt.
                </b-col>
                <b-col class="footercol">
                    Contact informatie: <br>
                    Email:  info@newcreative.nl <br>
                    KVK 78021901
                </b-col>
                <b-col class="footercol">
                    Sociale media <br>
                    <a href='https://www.instagram.com/dirk.newcreative/'>Instagram</a>
                </b-col>
            </b-row>
        </b-container>
</template>

<script>
export default {

}
</script>

<style>
.footercol {
    margin-top: 30px;
}
</style>