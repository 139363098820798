/* tslint:disable */
import Vue from "vue";
//import store from "./store";
import Router from "vue-router";
//import NProgress from "nprogress";

Vue.use(Router);

// create new router
const routes = [
	{
		path: "/home",
		redirect: "/"
	},
	{
		path: "/",
		component: () => import("./components/content/Home.vue"),
	},
	{
		path: "/Gallery",
		component: () => import("./components/content/Gallery.vue"),
	},
	{
		path: "/Articles",
		component: () => import("./components/content/Article.vue"),
	},
	{
		path: "/GalleryItem",
		component: () => import("./components/content/GalleryItem.vue"),
	},
	{
		path: "/Dashboard",
		component: () => import("./components/Dashboard.vue")
	},
	{
		path: "/Exhibition",
		component: () => import("./components/ExhibitionFlyer.vue")
	}
];

const router = new Router({
	mode: "history",
	linkActiveClass: "open",
	routes,
	/*
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 };
	}
	*/
});

export default router;