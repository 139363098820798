<template>
    <b-container class="topbar mb-5">
        <b-row class="mx-0">
            <b-col cols="lg-2" v-if="!mobile">
                <div class="logoContainer m-auto">
                    <b-img :src="imageRefs.logo" 
                    alt="logo"
                    class="logo"
                    @click="goTo('/Home')"/>
                </div>
            </b-col>
            <b-col
                align-self="end">
                <b-navbar
                    :class="mobile ? 'fixed-top' : 'mt-2'"
                    style="width: 100%"
                    toggleable="sm">
                    <b-nav
                        style="width: 100%"
                        :vertical="mobile"
                        :toggleable="mobile"
                        fill
                        :align="textalign">
                    <div class="navbar mobilebanner">
                        <b-img 
                            v-if="mobile"
                            :src="imageRefs.logo"
                            @click="
                                shownavbar = false;
                                goTo('/Home');
                                "
                            style="
                                max-width: 75px;
                                align-self: start;
                                margin-left: 0px"/>
                        <b-navbar-toggle 
                            target="navbar-toggle-collapse"/>
                    </div>
                    <b-collapse
                        id="navbar-toggle-collapse"
                        :visible="!mobile"
                        v-model="shownavbar"
                        is-nav
                        :style="mobile ? 'box-shadow: 0px 5px 5px slategray' : ''">
                        <b-nav-item
                            :align="textalign"
                            @click="goTo('/Gallery')">
                            Galerij
                        </b-nav-item >
                        <b-nav-item
                            :align="textalign"
                            @click="goTo('/Articles')">
                            Blog
                        </b-nav-item>
                        <b-nav-item
                            :align="textalign">
                            Over mij
                        </b-nav-item>
                        <b-nav-item
                            :align="textalign">
                            Werkzaamheden
                        </b-nav-item>
                        <b-nav-item
                            :align="textalign">
                            Contact
                        </b-nav-item>
                        </b-collapse>
                    </b-nav>
                </b-navbar>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import logo from "../assets/images/Logo_300.png"
import instagramLogo from "../assets/images/instagram-bw.png"


export default {
    data() {
        return {
            imageRefs: {
                logo: logo,
                instaLogo: instagramLogo
            },
            shownavbar : false
        }
    },
    methods : {
        goTo(target) {
            this.$router.push(target).catch(() => {});
        }
    },
    computed : {
        textalign() {
            if (this.mobile) {
                return "left"
            }
            return "right";
        },
        mobile() {
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
        }
    }
}
</script>

<style>
    @import "../assets/style/site.css";
    .logoContainer {
        background-color: white;
        transition: all .1s ease;
        display: block;
        width: fit-content;
    }
    .logo {
        display: block;
        overflow: hidden;
        transition: all .1s ease;
        backface-visibility: hidden;
        max-width: 100%;
    }
    .logoContainer:hover {
        outline: 5px solid;
        outline-color: #FF005D;
    }
    .logoContainer .logo:hover {
        opacity: .8;
    }
    .topbarbuttons {
        position: inherit;
        width: 100%;
    }
    .instagrambutton {
        height: 30px;
        transition: all .1s ease;
        padding: 2px;
        margin-right: 2px;
    }
    .instagrambutton:hover {
        background-blend-mode: multiply;
        outline: 2px solid;
        outline-color: #FF005D;
    }
    .instagrambutton.active {
        outline-color: #fd74a6;
    }
    .navbar-toggler .button{
        height: 100% !important;
        width: 100% !important;
    }
    .navbar {
        padding: 0 !important;
        width: 100%;
        background-color: #FF005D;
        z-index: 10;
    }
    .navbar-toggler-icon {
        color: white !important;
    }
    .nav-link, .navbar-toggler{
        color: white !important;
        z-index: 11;
    }
    .navbar-toggler {
        border: 0px !important;
    }
    
    .nav-item {
        background-color : #FF005D !important;
        border-radius: 0px;
        border-color: #FF005D !important;
        transition: all .1s ease;
        font-family:Arial, Helvetica, sans-serif;
        font-weight: 700;
        letter-spacing: 0.075em;
        padding-left: .5rem;
        padding-right: .5rem;
    }
    .nav-item:hover {
        background-color: #FF005D !important;
        background-color: #fd74a6 !important;
    }
    .nav-item:focus, .navbar-toggler:focus  {
        background-color: #FF005D !important;
        box-shadow: none !important;
    }
    .nav-item:active, .navbar-toggler:active {
        background-color: slategray !important;
        box-shadow: none;
    }
    .mobilebanner {
        background-color: #FF005D;
    }
</style>