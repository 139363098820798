/*eslint-disable*/
import Vue from 'vue'
import Vuex from 'vuex'
import VueFetch from 'vue-fetch'
import axios from 'axios'

import { Fetch } from 'vue-fetch'

Vue.use(Vuex);
Vue.use(VueFetch);
 
const $ = Fetch({
  logging: true
});

const headers = {
  "Content-Type": "application/json"
}

const prd = true;

const devUrl = "https://localhost:64717";
const prdUrl = "https://api.newcreative.nl";


const apiUrl = prd ? prdUrl : devUrl;

//post with axios
const post = async (url, payload) => {
  if (prd) return;
  var response = await axios.post(url, payload, {headers})
  return response.data;
};
const get = async (url) => {
  var response = await axios.get(url, {headers})
  return response.data;
}
const del = async (url) => {
  var response = await axios.delete(url, {headers});
  return response.data;
}

export default new Vuex.Store({
  state: {
    cards: [],
    collections: [],
    productionBuild: prd
  },
  mutations: {
    addCard(state, card) {
      var index = state.cards.indexOf(state.cards.find(x => x.id === card.id));
      if ( index !== -1 ) {
        state.cards[index] = card;
      } else {
        state.cards.push(card);
      }
    },
    addCollection(state, collection) {
      var index = state.collections.indexOf(state.collections.find(x => x.id === collection.id));
      if (index !== -1) {
        state.collections[index] = collection;
      } else {
        state.collections.push(collection);
      }
    },
    setCards(state, cards) {
      state.cards = cards
    },
    setCollections(state, collections) {
      state.collections = collections;
    },
    removeCard(state, id) {
      state.cards = state.cards.filter(x => x.id !== id);
    }
  },
  actions: {
    //Galleries
    async getGalleryByName(context, galleryName) {
      var result =  await $.get(apiUrl + "/Galleries/" + galleryName);
      return result;
    },
    async getGalleryItem(context, galleryItemId) {
      var result =  await $.get(apiUrl + "/GalleryItems/" + galleryItemId);
      return result;
    },
    
    async getValues() {
      var result = await $.get(apiUrl + "/values");
      console.log(result);
    },
    //Cards:
    async getCards(context) {
      var result = await get(apiUrl + "/cards/getAll");
      context.commit("setCards", result);
      return result;
    },
    async getCardsByCollectionId(context, id) {
      var result = await get(apiUrl + "/cards/fromCollection/" + id);
      context.commit("setCards", result);
      return result;
    },
    async createOrUpdateCard(context, newCard) {
      var result = await post(apiUrl + "/cards/create", newCard);
      context.commit("addCard", result);
    },
    async deleteCard(context, card) {
      await del(apiUrl+"/cards/delete/" + card.id);
      if (card.type === "Gallery")
        await del(apiUrl+"/galleries/delete/" + card.contentId);
      context.commit("removeCard", card.id);
    },
    //Galleries:
    async getGallery(context, id) {
      var result = await get(apiUrl + "/galleries/get/" + id);
      return result;
    },
    async createOrUpdateGallery(context, gallery) {
      var result = await post(apiUrl + "/galleries/create", gallery);
      return result;
    },
    //Collections
    async getCollections(context) {
      var result = await get(apiUrl + "/collections/getAll/");
      context.commit("setCollections", result);
    },
    async createCollection(context, collection) {
      //representing a collecion of cards. (used to be gallery)
      var result = await post(apiUrl + "/collections/create", collection);
      context.commit("addCollection", result);
      return result.id;
    },
    async getCollectionByTitle(context, title) {
      var result = await get(apiUrl + "/collections/getByTitle/" + title);
      return result;
    }
  }
})